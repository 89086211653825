import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { FC, useState } from 'react';
import { IMenuItem } from '../../interfaces/menu';

import * as styles from './Menu.module.scss';
import MenuItem from './MenuItem';

const Menu: FC = () => {
    const [isOpened, setIsOpened] = useState(false);

    const toggle = (_e: React.MouseEvent<HTMLAnchorElement>, forcedValue?: boolean) => {
        if (forcedValue !== undefined) {
            setIsOpened(forcedValue);
        }
        else {
            setIsOpened(!isOpened);
        }
    };

    const { general, services } = useStaticQuery(graphql`
        {
            general: sanityGeneral {
                logo {
                    asset {
                        url
                        gatsbyImage(width: 80)
                    }
                }
            }

            services: allSanityService {
                edges {
                    node {
                        _id
                        slug {
                            current
                        }
                        name
                    }
                }
            }
        }
    `);

    const links: IMenuItem[] = [
        {
            to: '/',
            text: 'Accueil',
        },
        {
            text: 'Notre savoir faire',
            to: '/#prestations',
            children: services.edges.map((service: any) => ({
                to: `/services/${service.node.slug.current}`,
                text: service.node.name,
            }))
        },
        {
            to: '/projets',
            text: 'Nos projets',
        },

        {
            to: 'https://www.treppenmeister.com/fr/escalier-menuiserie-bordeau-mayenne-53/',
            text: 'Escaliers Treppenmeister',
        },
        {
            to: '/apropos',
            text: 'À propos',
        },
        {
            to: '/emplois',
            text: 'Offres d\'emploi',
        },
    ];

    return (
        <div className={styles.menu}>
            <Link to='/' onClick={(e) => toggle(e, false)}>
                <img src={general.logo.asset.url} alt="Logo" width={80} />
            </Link>

            <nav className={`${!isOpened && styles.closed}`}>
                <ul>
                    {
                        links.map(link => (
                            <MenuItem key={link.text} text={link.text} to={link.to} toggleMenu={!link.children ? toggle : undefined}>
                                {
                                    link.children && link.children.map(child => (
                                        <MenuItem key={child.text} text={child.text} to={child.to} toggleMenu={toggle} />
                                    ))
                                }
                            </MenuItem>
                        ))
                    }
                </ul>
            </nav>

            {/* @ts-ignore */}
            <button className={styles.hamburgerButton} onClick={toggle}>
                <i className={`fa fa-${isOpened ? 'times' : 'bars'}`} />
            </button>
        </div>
    );
};

export default Menu;