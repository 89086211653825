import React, { FC } from 'react'

import * as styles from './Button.module.scss'

interface Props {
    text: string
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    light?: boolean
    type?: 'button' | 'submit' | 'reset'
    disabled?: boolean
}

const Button: FC<Props> = ({ text, onClick, light, type, disabled }) => {
    return (
        <button
            onClick={onClick}
            type={type}
            className={`${styles.button} ${light ? styles.light : undefined}`}
            disabled={disabled}
        >
            {text}
        </button>
    )
}

export default Button