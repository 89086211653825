import { Link } from 'gatsby';
import React, { FC, ReactElement } from 'react';

import * as styles from './Menu.module.scss';

interface Props {
    to: string;
    text: string;
    children?: ReactElement[];
    toggleMenu?: (e: any) => void;
}

const MenuItem: FC<Props> = ({ text, children, to, toggleMenu = () => { } }) => {

    const internal = /^\/(?!\/)/.test(to);

    return (
        <li className={styles.menuItem} onClick={toggleMenu}>
            {
                internal ? (
                    <Link
                        to={to || ''}
                        className={`${styles.link} ${children && styles.hasChildren}`}
                        activeClassName={to && styles.active}
                    >
                        {text}
                    </Link>) : (
                    <a
                        href={to || ''}
                        className={`${styles.link} ${children && styles.hasChildren}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {text}
                    </a>
                )

            }

            {
                children && (
                    <ul className={styles.submenu}>
                        {children}
                    </ul>
                )
            }
        </li>
    );
};

export default MenuItem;