import React, { FC } from 'react';
import ContactSection from '../Sections/Contact';
import Footer from './Footer';
import { CookieConsent } from 'react-cookie-consent';

import * as styles from './Layout.module.scss';
import Menu from './Menu';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

interface Props {
    children: React.ReactNode;
}

const Layout: FC<Props> = ({ children }) => {

    const acceptCookies = () => {
        const location = typeof window !== 'undefined' ? window.location : undefined;
        if (location) {
            initializeAndTrack(location);
            // set gatsby-gdpr-google-analytics cookie to true
            document.cookie = 'gatsby-gdpr-google-analytics=true; path=/';
        }
    };

    return (
        <div className={styles.layout}>
            <CookieConsent onAccept={acceptCookies} enableDeclineButton buttonText="Accepter" declineButtonText="Refuser">
                Ce site utilise des cookies pour vous garantir la meilleure expérience sur notre site. Ces cookies sont utilisés à des fins de statistiques.
            </CookieConsent>
            <header>
                <Menu />
            </header>
            <main>
                {children}
            </main>
            <ContactSection />
            <Footer />
        </div>
    );
};

export default Layout;