import React, { FC } from 'react'

import * as styles from './BlockTitle.module.scss'

interface Props {
    title: string
}

const BlockTitle: FC<Props> = ({ title }) => {
    return (
        <h3 className={styles.blockTitle}>
            {title}
        </h3>
    )
}

export default BlockTitle