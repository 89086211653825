import React, { FC, useState } from 'react';
import Button from '../Buttons/Button';

import * as styles from './Contact.module.scss';

const ContactSection: FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [actualStepEmpty, setActualStepEmpty] = useState(true);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
    setActualStepEmpty(true);
  };

  const displayModeForm = (step: number) => {
    if (currentStep === step) {
      return 'block';
    }
    return 'none';
  };

  /**
   * Handle change on input to check if the input is empty or not
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === '') {
      setActualStepEmpty(true);
    } else {
      setActualStepEmpty(false);
    }
  };

  return (
    <section className={styles.contactSection}>
      <div className={styles.contactContainer}>
        <h2 className={styles.title}>
          Vous avez un projet ?
        </h2>
        <h3 className={styles.subTitle}>
          Contactez-nous
        </h3>

        <div className={styles.formContainer}>
          {/* @ts-ignore */}
          <form data-netlify="true" action="/" name="contact-form" method="post" netlify-honeypot="bot-field" className={styles.form}>
            <input type="hidden" name="form-name" value="contact-form" />

            <p style={{ display: 'none' }}>
              <label>
                Don’t fill this out if you’re human: <input name="bot-field" />
              </label>
            </p>

            <div style={{ display: displayModeForm(0) }}>
              <label htmlFor="name">
                Votre nom
              </label>
              <input type="text" id="name" name="name" required onChange={handleChange} />
            </div>

            <div style={{ display: displayModeForm(1) }}>
              <label htmlFor="email">
                Votre adresse email
              </label>
              <input type="email" id="email" name="email" required onChange={handleChange} />
            </div>

            <div style={{ display: displayModeForm(2) }}>
              <label htmlFor="message">
                Votre message
              </label>
              <textarea id="message" name="message" required onChange={handleChange} />
            </div>

            <div className={styles.buttonsContainer}>
              {
                currentStep < 2 && (
                  <Button type="button" onClick={nextStep} text="Suivant" disabled={actualStepEmpty} />
                )
              }
              <div style={{ display: displayModeForm(2) }}>
                <Button type="submit" text="Envoyer" disabled={actualStepEmpty} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;