import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './Footer.module.scss';
import BlockTitle from '../Titles/BlockTitle';
import { graphql, useStaticQuery } from 'gatsby';
import { PortableText } from '@portabletext/react';

const Footer: FC = () => {

  const { general } = useStaticQuery(graphql`
      {
        general: sanityGeneral {
          title
          description
          phone
          email
          address {
            street
            city
            zip
            country
          }
          _rawOpeningHours
          logo {
            asset {
              url
            }
          }
          socials {
            name
            link
            icon {
              name
            }
          }
        }
      }
    `);

  return (
    <>
      <footer className={styles.footer}>
        <div>
          <img src={general.logo.asset.url} width={80} alt="Logo" loading='lazy' />
          <p>{general.description}</p>
          <PortableText value={general?._rawOpeningHours} />
          <div className={styles.socialsContainer}>
            {
              general.socials.map((social: any) => (
                <a key={social.link} href={social.link} className={styles.social} target="_blank" rel="noreferrer" aria-label={social.name}>
                  <i className={`fab fa-${social.icon.name}`} />
                </a>
              ))
            }
          </div>
        </div>

        <div className={styles.informationContainer}>
          <div>
            <BlockTitle title='Rencontrons-nous' />
            <a href={`https://www.google.fr/maps/place/${(general.address?.street + '+' + general.address?.zip + '+' + general.address?.city + '+' + general.address?.country).toString().replace(' ', '+')}`} target="_blank" rel='noopener noreferer'>
              <address>
                <p>
                  {general.address?.street}
                </p>
                <p>
                  {general.address?.zip} {general.address?.city}, {general.address?.country}
                </p>
              </address>
            </a>
          </div>

          <div>
            <BlockTitle title='Contactez-nous' />
            <p>

              <a href={`tel:${general.phone}`}>
                {general.phone.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}
              </a>
            </p>
            <p>
              <a href={`mailto:{general.email}`}>
                {general.email}
              </a>
            </p>
          </div>
        </div>

      </footer>
      <div className={styles.credits}>
        <p>
          Création : Agence <a href="https://pichenette.studio" target="_blank">Pichenette</a>
        </p>
      </div>
    </>
  );
};

export default Footer;